


.sidebar{
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	z-index: 1021;
}

.flyout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: space-between;
}

.select {
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent;
	background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-position-x: 100%;
	background-position-y: 2px;
	border: 1px solid #dfdfdf;
}

.btn-v1{
	background-color: #ff6f01;
	color: #f5f5f5;
}

.btn-v1:hover{
	background-color: #65005A;
	color: #ff6f01;
}

.full-width{
	width: 100% !important;
	margin: 0 !important;
}

.gallery-button_all{
	width: auto;
	height: 250px;
	position: relative;
	user-select: none;
	cursor: pointer;	
	border: 2px solid #f5f5f5;
	border-top: none;
	border-bottom: none;
}

.gallery-button_all div{
	backdrop-filter: blur(22px) brightness(1.61) contrast(73%) saturate(147%);
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.gallery-button_all span{
	font-weight: 700;
	border: 2px solid;
	border-radius: 10px;
	padding: 0.7rem;
	padding-left: 2rem;
	padding-right: 2rem;
	color: #f5f5f5;
}

.gallery-button_all span:hover{
	color: #ff6f01;
}

.icon-circle {
    background-color: #ff6f00;
    border-radius: 50%;
    width: 61px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icoAdd span {
    font-size: 40px;
    color: white; 
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}

@keyframes skeleton-loading {
	0% {
	  background-color: hsl(200, 20%, 80%);
	}
	100% {
	  background-color: hsl(200, 20%, 95%);
	}
  }


.is-loading {
	animation: skeleton-loading 1s linear infinite alternate;
}

/*FONTES*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
	font-family: 'Montserrat', sans-serif;
	margin: 0;
	overflow-x: hidden;
	
  }

  .gradient {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
    
    position: relative;
    
	}

	@keyframes placeHolderShimmer{
		0%{
			background-position: -468px 0
		}
		100%{
			background-position: 468px 0
		}
	}

  .border-30{
	  border-radius: 30px;
  }
  .btn-outline-30{
	border-radius: 30px;
	background-color: transparent;
	border-color:#4B515D;
	border-style: solid;
	border-width: 1px;
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
	font-size: 1rem;
	font-weight: 500;
  }

  .select-ios{
	-webkit-appearance: none;
    -moz-appearance: none;
  }

  .btn-outline-30:hover {
	border-color: darkorange;
  }

  .select-orange{
	  background-color: darkorange;
	  color: white;
	  border-color: darkorange;
  }

  .btn-tabs:hover{
	border-radius: 30px;
	border: none;
	background-color: orange;
	color: white
  }
  .btn-tabs:focus {
	box-shadow: 0 0 0 0;
	outline: 0;
  }
  .btn-tabs{
	border: solid 1px ;
	border-color: orange;
	border-radius: 30px;
	color: orange;
	background-color: transparent;
	padding: 0.7rem 0.9rem 0.7rem 0.9rem;
	appearance: none;
	font-weight: 500;
	margin-left: 0.4rem;
  }

  .orange-bg-card{
	  color: black;
	  background-color: white;
  }

	.tab-scroll::-webkit-scrollbar {
	width: 5px;
	height: 5px;
   }
   .tab-scroll::-webkit-scrollbar-thumb {
	background: #ffffff;
	border-radius: 0px;
   }
   .tab-scroll::-webkit-scrollbar-thumb:hover{
	background: #ffffff;
   }
   .tab-scroll::-webkit-scrollbar-track{
	background: #ffffff;
	border-radius: 0px;
   }
   .icon-rotated {
	transform: rotate(-230deg); /* Equal to rotateZ(45deg) */
   }

 .shadow-hover-card:hover {
	box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }
  

  @media (max-width: 991px) {
	.exc-mobile-nav{
		display: none;
	}	

  }

  @media (min-width: 100px) and (max-width: 767px) {
	.exc-mobile{
		display: none;
	}	
	.custom-secao-home{
		padding: 2rem;
	}
	.banner-responsive{
		min-height: 40vh;
	}
	.background-gradient{
		background-image: linear-gradient(to right, #ff7300ee,#ff7300aa, #ff730000);
		min-height: 40vh;
	}
	.background-home{
		background-image: url("img/2.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		min-height: 40vh;
		margin-right: 1vh;
		margin-left: 1vh;
		
	}
	.background-vender{
		background-image: linear-gradient(0deg, rgba(21, 21, 21, 0.2), rgba(21, 21, 21, 0.2)), url("img/2.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		min-height: 30vh;
	}
	.background-lotear{
		background-image: linear-gradient(0deg, rgba(21, 21, 21, 0.2), rgba(21, 21, 21, 0.2)), url("img/3.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		min-height: 40vh;
	}
	.background-corretores{
		background-image: url("img/4.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		min-height: 80vh;
	}
	.titulo-secao-p{
		font-size: 2.4rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}
	.ilustracao1{
	  display: none;
	}
	.footer-mobile{
		display: none;
	}
	.footer-icon-mobile{
		margin-top: 4rem;
	}
	.footer-mobile-center{
		text-align: center;
	}
	.footer-mobile-block{
		display: block;
	}
	.footer-mt5{
		margin-top: 6rem;
	}
	.footer-mt4{
		margin-top: 5rem;
	}

	.mobile-top-07rem{
		margin-top: 0.7rem;
	}

	.mobile-pc-left-1-3rem{
		margin-left: 1rem;
	}

	.mobile-pc-left-1-2rem{
		margin-left: 1rem;
	}
	.div-maps{
		width:"15rem";
	}
	.card-flutuante{
		display: none;
	}
	.nav_footer-mobile{
		position: sticky;
		position: -webkit-sticky;
		bottom: 0;
		margin-left: -1rem !important;
		margin-right: -1rem !important;
		background-color: #fff !important;
		z-index: 100;
		overflow: visible;
		
	}
	.col-principal-lote{
		margin-bottom: 0.7rem;
	}

	.fotoconteinner{
		background-color: black;
		padding-top: 70%;
		padding-bottom: 70%;
		width: 100%;
		margin-left: 0rem; 
		margin-right: 0rem; 
		margin-top: 0rem;
		margin-bottom: 0rem;
	}
	.fotoexp{
		width: 100%;
	}
	.btnfecharimg{
		display: block;
		margin-top:0.5rem;
		padding-top: 0.5rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		margin-left: 0.75rem;
		padding-bottom: 0.5rem;
		border-radius: 30px;
		font-size: 0.7rem;
		position: absolute;
		z-index: 100;
		font-weight: 600;
		background-color: rgba(0,0,0,.35);
	
	}
	.title-gradient{
		width: 85%;
	}

	.title-responsive{
		font-size: 1.2rem;
	}

	.value-responsive{
		font-size: 1.2rem;
	}

  }


  @media (min-width: 768px){
	.exc-desktop{
		display: none;
	}
	.title-responsive{
		font-size: 1.4rem;
	}

	.value-responsive{
		font-size: 1.5rem;
	}

	.custom-secao-home{
		padding: 2rem 3rem 2rem 10rem;
	}
	.titulo-secao-p{
		font-size: 3rem;		 
	}
	.title-gradient{
		width: 40%;
	}
  .footer-icon{
	margin-left: 4.2rem;
  }
  .footer-span{
	margin-left: 4.2rem;
  }

	.footer-mobile-block{
		display: none;
	}
	.footer-left{
		text-align: left;
	}
	.footer-mt10{
		margin-top: 7rem;
	}

	.footer-ml9{
		margin-left: 9rem;
	}

	.mobile-pc-left-1-3rem{
		margin-left: 3rem;
	}

	.mobile-pc-left-1-2rem{
		margin-left: 2rem;
	}

	.pc-left-1rem{
		margin-left: 1rem;
	}
	.card-flutuante{
		position: sticky;
		position: -webkit-sticky;
		top: 70px; /* required */
	}
	.div-maps{
		height: '100px';
	}
	.nav_footer-mobile{
		display: none;
	}
	.col-principal-lote{
		margin-bottom: 3rem;
	}
	.btnfecharimg{
		display: block;
		margin-top:0.5rem;
		padding-top: 0.5rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		margin-left: 0.75rem;
		padding-bottom: 0.5rem;
		border-radius: 30px;
		position: absolute;
		z-index: 100;
		font-weight: 600;
		background-color: rgba(0,0,0,.35);
	
	}
	.banner-responsive{
		min-height: 56vh;
	}
	
	.background-gradient{
		background-image: linear-gradient(to right, #ff7300, #ff7300dd, #ff730055, #ff730000, #ff730000);
		min-height: 60vh;
	}

	.background-home{
		background-image: url("img/2.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		min-height: 60vh;
		margin-right: 2vh;
		margin-left: 2vh;
	}
	.background-vender{
		background-image: linear-gradient(0deg, rgba(21, 21, 21, 0.2), rgba(21, 21, 21, 0.2)), url("img/2.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		min-height: 70vh;
	}
	.background-lotear{
		background-image: linear-gradient(0deg, rgba(21, 21, 21, 0.2), rgba(21, 21, 21, 0.2)), url("img/3.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		min-height: 70vh;
	}
	.background-corretores{
		background-image: url("img/4.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
		background-position-y: 4rem;
		min-height: 80vh;
	}
}


