.result-demo {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-top: none;
  padding: 15px;
  padding-top: 50px;
  color: grey;
  background-color: #f8f8f8;
  display: block;
  border-radius: 5px;
}
 
.form-check-input[type=checkbox]:checked:focus {
	background-color: #ff7300 !important;
}  

.form-check-input[type=checkbox]:checked{
  background-color: #ff7300 !important;
}

.form-check-input:checked:focus{
  background-color: #ff7300 !important;
}

.form-switch .form-check-input:checked[type=checkbox]:after{
  background-color: #ff7300 !important;
}

.result-demo:before {
  display: block;
  content: "Result:";
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px 15px;
  width: 100%;
  background-color: #e0e0e0;
  font-weight: 400;
}

.featured-lotes{  
  padding: 2rem;
  padding-bottom: 1.5rem;
  padding-top: 11rem;
  background-color: gray;
}

/* Desktop */
@media (min-width: 768px) {
  .featured-lotes{
    min-height: 460px;
    max-height: 530px;
    min-width: 330px;
    max-width: 330px;
    margin-right: 1rem;
  }
}

/* Mobile */
@media (min-width: 100px) and (max-width: 767px) {
  .featured-lotes{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}